/* Body.css */

/* Importing the font */
@import url("https://fonts.googleapis.com/css2?family=Lexend+Mega:wght@700&display=swap");

/* Global styles */
body {
  margin: 0;
  padding: 0;
  font-family: "Lexend Mega", sans-serif;
}

/* Header styles */
.header {
  background-color: #0f0e0e;
  color: #eeeeee;
  padding: 10px;
  font-size: 15px;
  font-weight: 700;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-title {
  margin-left: 18px;
}

.faq {
  margin-right: 18px;
}

/* Footer styles */
.footer {
  background-color: #0f0e0e;
  color: #eeeeee;
  padding: 10px 0;
  text-align: center;
  font-size: 15px;
  font-weight: 700;
}

/* Body container styles */
.body-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  background-image: url("home_background.jpg");
  background-size: 100%;
}

/* Left section styles */
.left-section {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  text-align: center;
  color: white;
  margin-bottom: 30px; /* Add some bottom margin to separate from the right section */
}

.left-section h1 {
  font-size: 40px;
  margin-bottom: 10px;
}

.left-section h2 {
  font-size: 45px;
  margin-top: 0;
}

.left-section h3 {
  font-size: 25px;
  margin-bottom: 10px;
}

/* Right section styles */
.right-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 30px; /* Add some top margin to separate from the left section */
}

/* Card styles */
.card {
  background-color: #f0f0f0;
  padding: 18px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px; /* Limit the maximum width of the card */
  margin-bottom: 20px;
}

.card h2 {
  font-size: 20px;
  margin-top: 0;
  margin-bottom: 20px;
}

.card input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  box-sizing: border-box;
}

.card button {
  width: 100%;
  padding: 10px;
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.card button:hover {
  background-color: #555;
}

.text-container {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
